import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/performance';

// eslint-disable-next-line import/no-unresolved
const firebaseConfig = require('../firebase-config.json');

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

const runner = async () => {
  const isSupported = await firebase.analytics.isSupported();
  if (isSupported) {
    console.log('Initializing');
    await firebase.analytics();
    await firebase.performance();
    console.log('Fin');
  }
};

runner();
