import React, { useState, useEffect, Component, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { WarningAmber } from '@mui/icons-material';
import { Grid, CircularProgress, Backdrop } from '@mui/material';
import { auth } from './services/firebase';
import './App.less';

const Root = React.lazy(() => import('./components/common/Root'));
const LandingPage = React.lazy(() => import('./components/landing-page'));
const SuccessPage = React.lazy(() => import('./components/success'));
const Header = React.lazy(() => import('./components/common/Header'));
const TransactionCompleted = React.lazy(() => import('./components/common/TransactionCompleted'));
const PolicyPage = React.lazy(() => import('./components/common/PolicyPage'));
const UnavailablePage = React.lazy(() => import('./components/common/UnavailablePage'));
const VerifyPayment = React.lazy(() => import('./components/verify-payment'));

export default function App() {
  const [showHelp, setShowHelp] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  async function initFirebase() {
    if (auth && auth.signInAnonymously) {
      await auth.signInAnonymously();
    }
    setLoggedIn(true);
  }

  useEffect(() => {
    initFirebase();
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  function pressModalButton(direction) {
    if (direction) {
      setShowHelp(direction);
    } else {
      setShowHelp(!showHelp);
    }
  }

  function pressFeedbackButton() {
    setShowFeedback(!showFeedback);
  }

  const fallbackSpinner = () => (
    <Backdrop open className="fallback-spinner">
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    <div className="height-100" role="main">
      <ErrorBoundary>
        <Router>
          <Suspense fallback={fallbackSpinner()}>
            <Switch>
              <Route exact path="/" render={(props) => <Root {...props} />} />
              <Route
                path={['/success/:sessionId', '/success']}
                render={(props) => (
                  <SuccessPage
                    {...props}
                    pressFeedbackButton={pressFeedbackButton}
                    setTransactionId={setTransactionId}
                    pressModalButton={pressModalButton}
                    showHelp={showHelp}
                    showFeedback={showFeedback}
                    transactionId={transactionId}
                    feedbackSubmitted={feedbackSubmitted}
                    setFeedbackSubmitted={setFeedbackSubmitted}
                    loggedIn={loggedIn}
                  />
                )}
              />
              <Route
                path={['/full-validation/:sessionTransId', '/success']}
                render={(props) => (
                  <SuccessPage
                    {...props}
                    pressFeedbackButton={pressFeedbackButton}
                    setTransactionId={setTransactionId}
                    pressModalButton={pressModalButton}
                    showHelp={showHelp}
                    showFeedback={showFeedback}
                    transactionId={transactionId}
                    feedbackSubmitted={feedbackSubmitted}
                    setFeedbackSubmitted={setFeedbackSubmitted}
                    loggedIn={loggedIn}
                  />
                )}
              />
              <Route
                path={['/status/:sessionId', '/status']}
                render={(props) => (
                  <SuccessPage
                    {...props}
                    pressFeedbackButton={pressFeedbackButton}
                    setTransactionId={setTransactionId}
                    pressModalButton={pressModalButton}
                    showHelp={showHelp}
                    showFeedback={showFeedback}
                    transactionId={transactionId}
                    feedbackSubmitted={feedbackSubmitted}
                    setFeedbackSubmitted={setFeedbackSubmitted}
                    loggedIn={loggedIn}
                  />
                )}
              />
              <Route exact path="/policies/:policy" component={PolicyPage} />
              <Route exact path="/unavailable/:obscuredLocationId" component={UnavailablePage} />
              <Route exact path="/verify/:sessionId" render={() => <VerifyPayment loggedIn={loggedIn} isPaymentPage={false} />} />
              <Route exact path="/payment/:sessionId" render={() => <VerifyPayment loggedIn={loggedIn} isPaymentPage />} />
              <Route
                exact
                path="/:obscuredLocationId/:obscuredSignId"
                render={(props) => (
                  <LandingPage
                    {...props}
                    className="testing"
                    showHelp={showHelp}
                    pressModalButton={pressModalButton}
                    showFeedback={showFeedback}
                    pressFeedbackButton={pressFeedbackButton}
                    transactionId={transactionId}
                    setTransactionId={setTransactionId}
                    feedbackSubmitted={feedbackSubmitted}
                    setFeedbackSubmitted={setFeedbackSubmitted}
                    loggedIn={loggedIn}
                    setInitLoaded
                  />
                )}
              />
              <Route path="/:obscuredLocationId/:obscuredSignId" render={(props) => <LandingPage {...props} isLoggedIn={loggedIn} />} />
              <Route exact path="/completed" component={TransactionCompleted} />
            </Switch>
          </Suspense>
        </Router>
      </ErrorBoundary>
    </div>
  );
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { errorMessage: '' };
  }

  static getDerivedStateFromError(error) {
    return { errorMessage: error };
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <Grid justifyContent="center" alignitems="center" container>
          <Grid item xs={12}>
            <Header />
            <div className="error-boundary" direction="vertical" align="center">
              <div className="error-boundary-header">
                <h3 className="error-boundary-header-text">Hmm. It looks like something went wrong.</h3>
              </div>
              <WarningAmber color="#dd3227" className="error-boundary-icon" />
              <div className="error-boundary-footer">
                <h3 className="error-boundary-header-text">Please refresh the page and try again.</h3>
              </div>
            </div>
          </Grid>
        </Grid>
      );
    }
    return this.props.children;
  }
}
